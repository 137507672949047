import { candidate as candidateService } from '../../services'
import { assessmentService } from '../../services'
import { STEPS as testSteps } from '../../pages/TestModule/flow'
import { STEPS as assessmentSteps } from '../../pages/AssmntStart/flow'

const modifyStateMap = {
    'flowControl/markAssessmentStepComplete': (state, action) => {
        const stepKey = action.payload
        state.assessment.progress[stepKey].completed = true
        return state
    },
    'flowControl/markTestStepComplete': (state, action) => {
        const stepKey = action.payload
        state.currentTest.progress[stepKey].completed = true
        return state
    },
    'flowControl/markTestComplete': (state) => {
        state.assessment.tests.currTestIndex += 1
        state.currentTest.index += 1
        state.currentTest.__initialized = false
        return state
    },
    'flowControl/markQuestionComplete': (state, action) => {
        state.currentTest.currAQIndex += 1
        state.currentTest.isSaving = false
        return state
    },
    'flowControl/markQualifyingQuestionComplete': (state, action) => {
        state.qualifyingWindow.currentIndex += 1
        return state
    },
    'flowControl/terminateAssessment': (state, action) => {
        const progress = JSON.parse(JSON.stringify(state.assessment.progress))
        for(let step in progress){
            if(!!progress[step].completed || step === assessmentSteps.Terminated.slug) continue
            progress[step].skipped = true
        }
        state.assessment.progress = progress
        return state
    },
    'flowControl/markLabCompleted': (state, action) => {
        const assessment = JSON.parse(JSON.stringify(state.assessment))
        if(assessment.labs.lastLabIndex <= assessment.labs.currLabIndex){
            assessment.progress[assessmentSteps.LabWindow.slug].completed = true
        }else{
            assessment.labs.currLabIndex += 1
            assessment.labs.currLabStatus = 'PENDING'
        }
        state.assessment = assessment
        return state
    },
}

export const flowControlSyncToDB = (store) => (next) => async(action) => {
    console.log(`______________Action: ${action.type}________________`)
    const state = store.getState().flowControl
    if(modifyStateMap[action.type]){
        const _state = JSON.parse(JSON.stringify(state))
        // modify current state according to matching action and using same reducer logic as redux do
        const payload = modifyStateMap[action.type](_state, action)

        // fire evaluate result api as soon as all tests and all labs are done. 
        // Or assessment get terminated       

        if(checkIfTerminated(action)){
            await assessmentService.getAssessmentResult({ isQualified: false })
        }
        else if(checkIfAllTestsAndLabsCompleted(payload)){
            await assessmentService.getAssessmentResult()
        }
        // update flow control data to the candidate invitation
        await candidateService.updateFlowControl(payload).catch(err => console.log(err))
    }
    next(action)
}

function checkIfAllTestsAndLabsCompleted(payload){
    const testStats = payload.assessment.tests
    const labStats = payload.assessment.labs
    const hasTestsOnly = testStats.totalTests > 0 && labStats.totalLabs === 0

    const testWindowProgress = payload.assessment?.progress?.[assessmentSteps.TestWindow.slug]
    const labWindowProgress = payload.assessment?.progress?.[assessmentSteps.LabWindow.slug]
    const testQuestionProgress = payload.currentTest?.progress?.[testSteps.Actual_Queston.slug]

    const testWindowDone = Boolean(testWindowProgress?.skipped || testWindowProgress?.completed)
    const lastTestQuestionDone = Boolean(testQuestionProgress?.completed && testStats.currTestIndex >= (testStats.totalTests - 1))
    const labWindowDone = Boolean(labWindowProgress?.skipped || labWindowProgress?.completed)

    return (hasTestsOnly && lastTestQuestionDone) || (testWindowDone && labWindowDone)
}

function checkIfTerminated(action){
    return action.type === 'flowControl/terminateAssessment'
}